import React from 'react';
import HeaderContainerFluid from '../Components/Header';
import NavHeader from '../Components/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container'; 
import Footer from '../Components/Footer';
import FooterBotttom from '../Components/FooterBotttom';
import './DigitalMarketing.css';
import Image from 'react-bootstrap/Image';
import digital from '../images/digitalmarketing.png';


function DigitalMarketing() {
  return (
    <div>
        <HeaderContainerFluid></HeaderContainerFluid>
        <NavHeader></NavHeader>
<Container fluid>
  <Container className='digitaldevcontent'>
    <Row>
<Col md={6}>
  <div>
  <h2>What Is Digital Marketing?</h2>
  <p style={{paddingTop:"15px", textAlign:"justify",}}>
  The term digital marketing refers to the use of digital channels to market products and services to consumers. This type of marketing involves the use of websites, mobile devices, social media, search engines, and other similar channels. Digital marketing became popular with the advent of the internet in the 1990s.
</p>    
<p style={{textAlign:"justify",}}>
Digital marketing involves some of the same principles as traditional marketing and is often considered an additional way for companies to approach consumers and understand their behavior. Companies often combine traditional and digital marketing techniques in their strategies. But digital marketing comes with its own set of challenges, including implicit bias.
</p>
  </div>
  <div>
  <ul className='nonestyle'>
          <li className='dlistspace'>Digital marketing involves marketing to consumers through digital channels, including websites, mobile devices, and social media platforms.</li>
          <li className='dlistspace'>This form of marketing is different from internet marketing, which is exclusively done on websites.</li>
          <li className='dlistspace'>Digital marketing relates to attracting customers via email, content marketing, search platforms, social media, and more.</li>
          <li className='dlistspace'>One of the biggest challenges digital marketers face is how to set themselves apart in a world that is oversaturated with digital marketing ads.</li>
          <li className='dlistspace'>Digital marketing comes with various challenges, including implicit bias.</li>


            </ul>
  </div>
  </Col>
  <Col md={6} style={{textAlign:"center"}}>
<Image src={digital} className='boxshadow' style={{width:"100%", height:"auto"}}alt="" />
  </Col>
      </Row>
  </Container>
  </Container>   
   <Footer></Footer>
    <FooterBotttom></FooterBotttom>
    </div>
  )
}

export default DigitalMarketing