import React, { useEffect , useState } from 'react';
import { useLocation } from 'react-router-dom';
// import { Navigate } from 'react-router-dom';
import parse from 'html-react-parser';
import Carousel from 'react-bootstrap/Carousel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import './ViewProject.css';
import Gallery from'./ProjectGallery';





function ViewProject() {



    const [pro , setProjects] = useState([]);

    const location = useLocation();


// const mdata = {

//     'pjid' : location.projectDetails.pid,
//     'pjname' :location.projectDetails.pname,
// }


const ProjectData = {

'pid' : location.state.pdid,
'cid' : location.state.ctid,
'pname' : location.state.pdname,
// 'pimage' : location.state.pdimage,
// 'pbanner' : location.state.pdbanner,
// 'pgallery' : location.state.pdgallery,
// 'pdescription' : location.state.pdtext,

}


// console.log('name of the project id', ProjectData.pid);
// console.log('name of the project name', ProjectData.pname);

// console.log('name of the cat id', ProjectData.cid);

useEffect(()=>{
const projectsViewDetails = async () =>{
    const projectDetailsapi = await fetch("https://dev.spiderhit.com/api/projects" ,
    { method: 'POST' , body : JSON.stringify({category_id : ProjectData.cid , project_id: ProjectData.pid }), 
    headers: {
        "Content-Type": "application/json"
        
      }
    
    });

const pdataapi = await projectDetailsapi.json();


setProjects(pdataapi.data);

}
projectsViewDetails();

},[]);



console.log(pro); 


  return (
    
    <div>
        {/* <div className='title'>{ProjectData.pname}</div> */}

        


{pro.map((projectDeatils)=>(
<div key={projectDeatils.id}>
  {/* <p>{projectDeatils.id}</p> */}
<Carousel fade>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={projectDeatils.banner}
          alt="First slide" style={{height:"450px"}}
        />
        {/* <Carousel.Caption>
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption> */}
      </Carousel.Item>
  
      
    </Carousel>

<Container>

<Row className="projectSpace">
  <Col sm={6}>
<h4>{projectDeatils.name}</h4>
<p>About {projectDeatils.name}</p>
{/* <p>Client:<span>{projectDeatils.user.name}</span></p> */}
<p>User Name: {projectDeatils.user && projectDeatils.user.name ? <span>{projectDeatils.user.name}</span> : <span>Spiderhit</span> }</p>
<br></br>
<p>{parse(projectDeatils.description)}</p>
  </Col>
  <Col sm={6}>
    <div>
    <img style={{objectFit:"contain", width: "100%", height:"100%", borderRadius:"10px"}} src={projectDeatils.image}></img>

    </div>
  </Col>
</Row>

</Container>
<Container>
  <Row className="projectSpace ">
    <div class="text-center">    <h4>Gallery</h4>
</div>
<Gallery></Gallery>
  </Row>
</Container>
{/* <p>{ __html: projectDeatils.description,}</p> */}


</div>



))

}

   </div>
  )
}

export default ViewProject;