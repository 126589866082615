import React from 'react';
import HeaderContainerFluid from '../Components/Header';
import NavHeader from '../Components/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container'; 
import Image from 'react-bootstrap/Image';
import Planing from'../images/file-edit-icon-1.png';
import Technical from '../images/brain-icon-1.png';
import Prototype from '../images/two-phones-icon.png';
import Development from '../images/coding-icon.png';
import Quality from '../images/testing-icon-150x150.png';
import publish from '../images/mvp-icon.png';

import Footer from '../Components/Footer';
import FooterBotttom from '../Components/FooterBotttom';
import './AppDevelopment.css';
import appDevelopment from '../images/appdevelopment.png';

function AppDevelopment() {
  return (
    <div>
<HeaderContainerFluid></HeaderContainerFluid>
<NavHeader></NavHeader>
<Container fluid>
  <Container className="appdevcontent">
    <Row>
<Col md={6}>
<h2>What Is App Development?</h2>
<p style={{paddingTop:"20px", textAlign:"justify"}}>
app development is the process in which developers create an application to be used on smartphones, tablets and other mobile devices. While app development sometimes involves creating a web-based app or a desktop version of the app, the majority of app development projects are deployed to mobile and tablet devices. There are many features and considerations that factor into app development
</p>
<h4 style={{paddingTop:"20px"}}>Types Of Apps</h4>
<ul className='nonestyle'>
  <li className='listspace'>Native apps</li>
  <li  className='listspace'>HTML5 apps</li>
  <li  className='listspace'>Hybrid apps</li>

</ul>
</Col>
<Col md={6} style={{textAlign:"center"}}>
    <Image className='boxshadow'  style={{objectFit:"contain",width:"auto", height:"350px"}} src={appDevelopment} alt="app development" />
</Col>
    </Row>
    <Row className='appcontent1'>
      <div style={{textAlign:"center"}}>
        <h2>App Development Lifecycle</h2>
      </div>
<Row style={{textAlign:"center"}} className='appcontent'>
<Col className='col' md={4}>
  <div  style={{textAlign:"center"}}className='boxshadow'>
    <Image src={Planing} alt="planing" style={{width:"auto", height:"100px"}} />
    <h4 style={{paddingTop:"10px"}}>Planning</h4>
    <p>This first stage involves completing a business analysis and creating a mobile strategy. A business analyst, marketer and project manager are usually involved.</p>
  </div>
</Col>
<Col  className='col' md={4}>
  <div  style={{textAlign:"center"}}className='boxshadow'>
    <Image src={Technical} alt="planing" style={{width:"auto", height:"100px"}} />
    <h4 style={{paddingTop:"10px"}}>Technical</h4>
    <p>
    In this next stage, a technical writer is responsible for describing all technical details and requirements.

</p>
  </div>
</Col>
<Col className='col' md={4}>
  <div  style={{textAlign:"center"}}className='boxshadow'>
    <Image src={Prototype} alt="planing" style={{width:"auto", height:"100px"}} />
    <h4 style={{paddingTop:"10px"}}>Prototyping</h4>
    <p>
    In the prototyping phase, the sketch, wireframes and app skins are created. This is usually completed by a UX/UI designer.
</p>
  </div>
</Col>
<Col className='col' md={4}>
  <div  style={{textAlign:"center"}}className='boxshadow'>
    <Image src={Development} alt="planing" style={{width:"auto", height:"100px"}} />
    <h4 style={{paddingTop:"10px"}}>Developing</h4>
    <p>
    This phase includes front-end and back-end coding segments and is performed by developers.

</p>
  </div>
</Col>
<Col className='col' md={4}>
  <div  style={{textAlign:"center"}}className='boxshadow'>
    <Image src={Quality} alt="planing" style={{width:"auto", height:"100px"}} />
    <h4 style={{paddingTop:"10px"}}>Quality Assurance</h4>
    <p>
    During the quality assurance phase, tech requirements are tested and the device capability is reviewed to ensure that the app is working as it should.</p>
  </div>
</Col>
<Col className='col' md={4}>
  <div  style={{textAlign:"center"}}className='boxshadow'>
    <Image src={publish} alt="planing" style={{width:"auto", height:"100px"}} />
    <h4 style={{paddingTop:"10px"}}>Publishing</h4>
    <p>
    Finally, the app is published to the app store. Maintenance is provided on an ongoing basis as updates, new releases and new bugs arise.

</p>
  </div>
</Col>
</Row>
    </Row>
  </Container>
  </Container>   
   <Footer></Footer>
    <FooterBotttom></FooterBotttom>


    </div>
    
  )
}

export default AppDevelopment