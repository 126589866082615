import React from'react';
import './HomeAbout.css';
import HomeAboutImage from '../images/image-1-about.jpg';
import Image from 'react-bootstrap/Image';



function HomeAbout(){

    return(

<div>

<section class="about-section">
        <div class="container">
            <div class="row">                
                <div class="content-column col-lg-6 col-md-12 col-sm-12 order-2">
                    <div class="inner-column">
                        <div class="sec-title1">
                            <span class="title1">About Spiderhit Infotech Private Limited</span>
                            <h2>We are Creative Tech Enthusiast working since 2015</h2>
                        </div>
                        <div class="text">We are leading Software solutions providing company all over the world doing over 8 years. Providing Services Web Designing and Development, App Development, Graphic Designing and Digital Marketing.Spiderhit Company Develop Customer Requirement based Websites and Applications.</div>
                      <div class="text">
                      Providing Best Software Solutions for Customers and Improving Customer Satisfaction.providing the best software solutions for customers makes it easy for their business.
                      </div>
                        <div class="btn-box">
                            <a href="#" class="theme-btn btn-style-one">Contact Us</a>
                        </div>
                    </div>
                </div>

                <div class="image-column col-lg-6 col-md-12 col-sm-12">
                    <div class="inner-column wow fadeInLeft">
                      <div class="author-desc">
                        <h2>Suresh Kanna</h2>
                        <span>Web Developer</span>
                      </div>
                        <figure class="image-1"><a href="#" class="lightbox-image" data-fancybox="images">
                            <Image src={HomeAboutImage} title="Suresh Kanna" className="boxshadow"  />

                                
                                </a></figure>
                     
                    </div>
                </div>
              
            </div>
  
        </div>
    </section>
    </div>

)

}
 
export default HomeAbout;