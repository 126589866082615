import React from 'react';
import HeaderContainerFluid from '../Components/Header';
import NavHeader from '../Components/Nav';
import Footer from "../Components/Footer";
import FooterBotttom from "../Components/FooterBotttom";
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './ContactPage.css';
import ContactForm from '../Components/ContactForm';

function ContactPage() {
  return (
    <div>
        <HeaderContainerFluid></HeaderContainerFluid>
        <NavHeader></NavHeader>
        <div className='title'>Contact Us</div>
        <Container className='contentCont'>
          <Row >
            <Col sm={6}>
              <div className='boxshadow'>
                <h4>Contact Us:</h4>
                <br></br>
               <div>

<p className='di'><b>Address:</b> 12-5-136,Near LIG Busstop,Housing Board<br/>
Anantapur-515001</p>
               </div>
               <div>

<p className='di'><b>Phone:</b> 7411122189</p>
               </div>
               <div>

<p className='di'><b>Mail:</b>Support@spiderhit.com</p>
               </div>
              </div>
            </Col>
            <Col sm={6}>
              <div className='boxshadow'>

              <h4>Leave Message</h4>
<ContactForm></ContactForm>
              </div>
            </Col>

          </Row>
        </Container>

        <Footer></Footer>
<FooterBotttom></FooterBotttom>
    </div>
  )
}

export default ContactPage