import React from 'react';
import { Container } from 'react-bootstrap';
import './FooterBottom.css';




function FooterBotttom() {
  return (
<Container fluid className='FooterBlw'>
    <Container >
        <p className='copyText'>Copyright @2024 All Rights Reserved</p>
    </Container>
</Container>  )
}

export default FooterBotttom