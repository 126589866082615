import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";

function HomeBanners() {
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    const bannersapi = async () => {
      const banners = await fetch(
        "https://jsonplaceholder.typicode.com/photos"
      );

      const json = await banners.json();
      setBanners(json);
    };
    bannersapi();
  }, []);

  return (
    <Carousel>
      {banners.slice(0, 3).map((banner) => (
        <Carousel.Item key={banner.id}>
          <img
            className="d-block w-100"
            src={banner.url}
            style={{ height: "500px" }}
            alt="First slide"
          />
          <Carousel.Caption>
            <h3>{banner.title}</h3>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default HomeBanners;
