import React from 'react';
import './App.css';
import HeaderContainerFluid from './Components/Header';
import NavHeader from './Components/Nav';
import HomeBanners from './Components/HomeBanners';
import About from './Components/About';
import HomeAbout from './Components/HomeAbout';
import FooterBotttom from './Components/FooterBotttom';
import Footer from './Components/Footer';
import HomeProjects from './Components/HomeProjects';


function App() {
  return (
    <div className="header">
      <HeaderContainerFluid></HeaderContainerFluid>
      <NavHeader></NavHeader>
      <HomeBanners></HomeBanners>
      <HomeAbout></HomeAbout>
      <About></About>
      <HomeProjects></HomeProjects>
      <Footer></Footer>
      <FooterBotttom></FooterBotttom>

    
</div>
  );
}

export default App;
