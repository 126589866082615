import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
createBrowserRouter,
  RouterProvider,
  
  
} from "react-router-dom";
import AboutPage from './Pages/AboutPage';
import ContactPage from './Pages/ContactPage';
import BulksmsPage from './Pages/BulksmsPage';
import Ecommerce from './Pages/Ecommerce';
import Terms from './Pages/Terms';
import Projects from './Pages/Projects';
import ProjectsCtegoriList from './Pages/ProjectsCtegoriList';
import TextComponent from './Pages/TextComponent';
import OtpVerify from './Pages/OtpVerify';
import ProjectView from './Pages/ProjectView';
import WebDesigning from './Pages/WebDesigning';
import WebDevelopment from './Pages/WebDevelopment';
import DigitalMarketing from './Pages/DigitalMarketing';
import AppDevelopment from './Pages/AppDevelopment';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import Cancellation from './Pages/Cancellation';
import Delivery from './Pages/Delivery';



const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
  },
  {
    path: "about",
    element: <AboutPage/>,
  },
  {
    path: "projects",
    element: <Projects/>,
  },
  {
    path: "project_category_list",
    element: <ProjectsCtegoriList/>,
  },
  {
    path: "otp_verify",
    element: <OtpVerify/>,
  },
  {
    path: "Project_View",
    element: <ProjectView/>,
  },
  {
    path: "web_designing",
    element: <WebDesigning/>,
  },
  {
    path: "web_development",
    element: <WebDevelopment/>,
  },
  {
    path: "app_development",
    element: <AppDevelopment/>,
  },

  
  {
    path: "digital_marketing",
    element: <DigitalMarketing/>,
  },
  

  
  {
    path: "bulksms",
    element: <BulksmsPage/>,
  },
  {
    path: "ecommerce",
    element: <Ecommerce/>,
  },
  {
    path: "contactUs",
    element: <ContactPage/>,
  },
  {
    path: "terms",
    element: <Terms/>,
  },
  {
    path: "PrivacyPolicy",
    element: <PrivacyPolicy/>,
  },
  {
    path: "Cancellation",
    element: <Cancellation/>,
  },
  {
    path: "Delivery",
    element: <Delivery/>,
  },

  {
    path: "text",
    element: <TextComponent/>,
  },
  
]);



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>

<RouterProvider router={router}/>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
