import React from 'react';
import HeaderContainerFluid from '../Components/Header';
import NavHeader from '../Components/Nav';
import Footer from '../Components/Footer';
import FooterBotttom from '../Components/FooterBotttom';

function Terms() {
  return (
    <div>
    <HeaderContainerFluid></HeaderContainerFluid>
    <NavHeader></NavHeader>
    <div><p>Terms and Conditions</p></div>
    <Footer></Footer>
    <FooterBotttom></FooterBotttom> 
    </div> 
    )
}

export default Terms;