import React from 'react';
import HeaderContainerFluid from '../Components/Header';
import NavHeader from '../Components/Nav';
import Footer from "../Components/Footer";
import FooterBotttom from "../Components/FooterBotttom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

function Ecommerce() {
  return (
    <div>
    <HeaderContainerFluid></HeaderContainerFluid>
    <NavHeader></NavHeader>
    <div className='title'>E-Commerce</div>
    <Row>
    <Container>
        <Col>hai
        </Col>
        <Col>hai
        </Col>
      </Container>
    </Row>
      
    
    <Footer></Footer>
<FooterBotttom></FooterBotttom>
</div>
  )
}

export default Ecommerce;