import React,{useEffect , useState} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './ProjectList.css';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import parse from 'html-react-parser';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { Button } from "react-bootstrap";



// import { Navigate } from 'react-router-dom';


function ProjectList() {
   
    const [projects , setProjects] = useState([]);

    const navigate = useNavigate();

const location = useLocation();



const data = {
    'mail': location.state.email,
    'fname':location.state.pname,
    
}


function handleClick(e){



navigate('/Project_View',{


  state:{
    pdid:e.target.id,
    pdname:e.target.name,
    ctid:data.mail,

  }
});


  }



console.log(data.mail);




    useEffect (()=>{

       projectslist(); 

    },[]);


const projectslist = async () =>{
const proje = await fetch("https://dev.spiderhit.com/api/projects" ,
{ method: 'POST' , body : JSON.stringify({category_id : data.mail}), 
headers: {
    "Content-Type": "application/json"
    
  }

});


const projectsapi = await proje.json();
setProjects(projectsapi.data);



}





return(

    


<div>
<div className='title'>{data.fname}</div>
<Container className='spacecontent'>
     <Row className='spacecontt'>
        { projects.map((projectsView)=>(
<Col md={4}>
          <div key={projectsView.id} className=' boxshadow '>
            {/* <img class="imgcard"  style={{width:'auto', height:'auto'}} rounded></img> */}
            <Card.Img variant="top" src={projectsView.image}  alt={projectsView.name} />
            <Card.Body>

            <Row className="d-flex align-items-center justify-content-center">
            <h5 className="cardTitle">{projectsView.name}</h5>

              <Col >
                {/* <p>{projectsView.user.name}</p> */}
                <p>No of Projects : 5</p>
              </Col>
              <Col  >
              <div style={{textAlign:"end"}}>
              {/* <button onClick={handleClick} id={projectsView.id}
             catid={data.mail}  name={projectsView.name}
            >View More</button> */}
             <Button variant="primary" onClick={handleClick} id={projectsView.id}
               name={projectsView.name}
            >View More</Button>
            </div>
            </Col>

            </Row>
            
               
             </Card.Body>
          </div>
          </Col>
          ))

        }
        </Row>
       
      </Container>
</div>


);


     
    }
 


export default ProjectList;

