import React from 'react';
import HeaderContainerFluid from '../Components/Header';
import NavHeader from '../Components/Nav';
import Footer from "../Components/Footer";
import FooterBotttom from "../Components/FooterBotttom";
import './BulksmsPage.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";




function BulksmsPage() {

  const navigate = useNavigate();

function handleSubmit(){

  navigate('/contactUs');



}


  return (
    <div>
    <HeaderContainerFluid></HeaderContainerFluid>
    <NavHeader></NavHeader>
    <div className='title'>Bulk SMS</div>
    <div className='smsspace'>
    <Container>

<Row>
  <Col md={4}>
    <div className='boxshadow text-center'>
<h2>Promotional SMS </h2>
<hr></hr>
<h5>Price : 1,00,000 SMS Rs.14,500</h5>
<hr></hr>
<ul className='nonestyle'>
  <li className='listspace'>Delivery Time : 10:00 Am to 9:00 PM</li>
  <li  className='listspace'>Sender Name Available With : 6 Numbers</li>
  <li  className='listspace'>DLT Registration Need</li>
  <li className='listspace'>No API Requests</li>

  <li className='listspace'>Rate/SMS (Ps) 0.145 Paise.</li>
</ul>
<hr></hr>
<Button variant="primary" size="lg" onClick = {handleSubmit}>
          Enquire Now
        </Button>{' '}
    </div>
  </Col>
  <Col md={4}>
    <div className='boxshadow text-center'>
<h2>Transactional SMS </h2>
<hr></hr>
<h5>Price : 1,00,000 SMS Rs.19,500</h5>
<hr></hr>
<ul className='nonestyle'>
  <li className='listspace'>Delivery Time : 24/7</li>
  <li  className='listspace'>Sender Name Available With : 6 Numbers</li>
  <li  className='listspace'>DLT Registration Need</li>
  <li className='listspace'>API Requests</li>

  <li className='listspace'>Rate/SMS (Ps) 0.195 Paise.</li>
</ul>
<hr></hr>
<Button variant="primary" size="lg" onClick = {handleSubmit}>
          Enquire Now
        </Button>{' '}
    </div>
  </Col>
  
  <Col md={4}>
    <div className='boxshadow text-center'>
<h2>WhatsApp SMS </h2>
<hr></hr>
<h5>Price : 1,00,000 SMS Rs.13,500</h5>
<hr></hr>
<ul className='nonestyle'>
  <li className='listspace'>Delivery Time : 10:00 AM - 6:00 PM</li>
  <li  className='listspace'>SMS Sent Through Vertual Number </li>
  <li  className='listspace'>DLT Registration Not Required</li>
  <li className='listspace'>Only Working Days</li>

  <li className='listspace'>Rate/SMS (Ps) 0.15 Paise.</li>
</ul>
<hr></hr>
<Button variant="primary" size="lg" onClick = {handleSubmit}>
          Enquire Now
        </Button>{' '}
    </div>
  </Col>
  </Row>  
  </Container>
 
   </div>
    <Footer></Footer>
<FooterBotttom></FooterBotttom>
</div>
  )
}

export default BulksmsPage