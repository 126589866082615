import React from 'react';
import HeaderContainerFluid from '../Components/Header';
import NavHeader from '../Components/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container'; 
import Footer from '../Components/Footer';
import './WebDevelopment.css';
import FooterBotttom from '../Components/FooterBotttom';
import Images from '../images/webdevelopment.png';
import Image from 'react-bootstrap/Image';


function WebDevelopment() {
  return (
    <div>
        <HeaderContainerFluid></HeaderContainerFluid>
        <NavHeader></NavHeader>
    <Container fluid>
      <Container className="webdevcontent">
        <Row>
          <Col md={6}>
           <h2>What Exactly is Web Development?</h2>
           <p style={{paddingTop:"15px", textAlign:"justify"}}>Web development is closely related to the job of designing the features and functionality of websites and apps (often called “web design”), but the “web development” term is usually reserved for the actual construction and programming of websites and apps.</p>
<p style={{paddingTop:"15px", textAlign:"justify"}}>Think of all the web pages you have used over the years – Web Developers built those sites, making sure they functioned properly and performed in ways that allowed for a great user experience. Web Developers do this by writing lines of code, using a variety of programming languages, which vary depending on the tasks they are performing and the platforms they are working on.</p>

          </Col>
          <Col md={6} style={{textAlign:"center"}}>
            
              <Image className="boxshadow" src={Images} style={{objectFit:"contain", width:"350px", height:"auto"}}alt="text" />
            
          </Col>
        </Row>
        <Row className="webcontent">
          <Col md={6}>
           <h2>What Do Web Designers Do?</h2>
           <ul className='nonestyle'>
  <li className='listspace'>Write the code that makes a website function, whether they work on the front end or back end (server-side)n</li>
  <li  className='listspace'>Create or implement designs demanded by a client or created by a design team </li>
  <li  className='listspace'>Need expert-level knowledge of a variety of programming languages, including HTML (HyperText Markup Language), CSS, PHP, and JavaScript but also extending to other languages (Ruby, C/C++, Python), frameworks, and libraries</li>
  <li className='listspace'>Rarely create mockups, select typography, or pick color palettes</li>

</ul>
          </Col>
          <Col md={6}>
          <h2>Types of Web Development</h2>
          <ul className='nonestyle'>
          <li className='listspace'>Front-End Development</li>
          <li className='listspace'>Back-End Development</li>

          <li className='listspace'>Full-Stack Development</li>


            </ul>
        

          </Col>
        </Row>
<br></br>
      </Container>
    </Container>
    <Footer></Footer>
    <FooterBotttom></FooterBotttom>
    </div>
  )
}

export default WebDevelopment