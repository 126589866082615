import React from 'react';
import HeaderContainerFluid from '../Components/Header';
import NavHeader from '../Components/Nav';
import Footer from '../Components/Footer';
import FooterBotttom from '../Components/FooterBotttom';

function Delivery() {
  return (
  <div>  
     <HeaderContainerFluid></HeaderContainerFluid>
    <NavHeader></NavHeader>
    <div>Delivery</div>
    <Footer></Footer>
    <FooterBotttom></FooterBotttom> 
    </div>
  )
}

export default Delivery