import React,{ useState, useEffect } from "react";
import ChildComponent from './ChildComponent';


function ParentComponent() {
    const [project, setproject] = useState([]);

    const [id, setId] = useState();
    const [name, setName] = useState('');



    const handleClick = () => {
        setId(2);
        setName('Jane');
      };


useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("https://dev.spiderhit.com/api/project/categories", {
        method: "GET"
       
      });
      const responseData = await response.json();
      setproject(responseData.data);

    };

    fetchData();
  }, []);

  return (
 <div>
    {project.map((item) => (

<div key={item.id}>
    <p>{item.name}</p>
    <button onClick={handleClick}>Change Data</button>
    <ChildComponent id={id} name={name} />

</div>


    ))}
      
    </div>  )
}

export default ParentComponent