import React from 'react';
import HeaderContainerFluid from '../Components/Header';
import NavHeader from '../Components/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container'; 
import Footer from '../Components/Footer';
import FooterBotttom from '../Components/FooterBotttom';
import './WebDesigning.css';
import WebImage from '../images/webdesinig.png';
import Webimage1 from '../images/webdesigningatp.png';
import Image from 'react-bootstrap/Image';


function WebDesigning() {
  return (
    <div>
        <HeaderContainerFluid></HeaderContainerFluid>
        <NavHeader></NavHeader>
    <Container fluid>
      <Container className="webspace">
        <Row>
          <Col md={6}>
<h2>What is Web Designing?</h2>
<p style={{paddingTop:"20px", textAlign:"justify",}}>Web designing is the process of planning, conceptualizing, and implementing the plan for designing a website in a way that is functional and offers a good user experience. User experience is central to the web designing process. Websites have an array of elements presented in ways that make them easy to navigate. Web designing essentially involves working on every attribute of the website that people interact with, so that the website is simple and efficient, allows users to quickly find the information they need, and looks visually pleasing. All these factors, when combined, decide how well the website is designed.</p>
          </Col>
          <Col md={6}>
            <div  style={{textAlign:"center"}}>
<Image src={WebImage} className="boxshadow" alt="web designing" style={{width:"auto", height:"350px"}} fluid />
            </div>
            </Col>
        </Row>
        <Row className="webcontent">
         
          <Col md={6}>
            <div  style={{textAlign:"center"}}>
<Image src={Webimage1} className="boxshadow" alt="webdesigningatp" style={{objectFit:"contain", width:"auto", height:"400px"}} fluid />
            </div>
            </Col>
            <Col md={6}>
<h2>What Do Web Designers Do?</h2>
<p style={{paddingTop:"20px", textAlign:"justify",}}>
Web designers create the design and layout of a website with their technical expertise and knowledge while keeping in mind the client’s requirements. They have a deep understanding of UI (user interface) and UX (user experience) and are adept at using design programs for creating visual elements. With their skills and expertise, web designers capture the brand’s identity in the design elements of the website.  </p>
         
         <h4 style={{paddingTop:"20px",}}>Key Benefits of Having a Good Web Design</h4>
         <ul className='nonestyle'>
  <li className='listspace'>An Impactful First Impression</li>
  <li  className='listspace'>Better Google Rankings </li>
  <li  className='listspace'>Brand Consistency</li>
  <li className='listspace'>Minimized Bounce Rates</li>

</ul>
         
          </Col>
        </Row>

      </Container>
    </Container>
    <Footer></Footer>
    <FooterBotttom></FooterBotttom>
    </div>
  )
}

export default WebDesigning