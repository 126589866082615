import React, { useEffect , useState } from 'react';
import { useLocation } from 'react-router-dom';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

// import lgThumbnail from 'lightgallery/plugins/thumbnail';
// import lgZoom from 'lightgallery/plugins/zoom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';




function ProjectGallery() {
    const [gallery , setGallery] = useState([]);
    

    const location = useLocation();
    const ProjectData = {

        'pid' : location.state.pdid,
        'pname' : location.state.pdname,
     
        }

useEffect(()=>{ 
    const projectsGallery = async () =>{
        const projectGalleryapi = await fetch("https://dev.spiderhit.com/api/projects" ,
        { method: 'POST' , body : JSON.stringify({ project_id: ProjectData.pid }), 
        headers: {
            "Content-Type": "application/json"
            
          }
        
        });
    
    const pgallery = await projectGalleryapi.json();
    
    
     setGallery(pgallery.data[0].gallery);
    


    }
    projectsGallery();
    },[]);

    if (gallery.length === 0) {
        return <p>No items in gallery.</p>;
      }
    
    // console.log('suresh', gallery);
  return (

    <div>
    
<Row>
{/* <Col sm={4}>
    <div style={{margin:"2px"}}>
    <img src={item} alt="texr" style={{objectFit:"contain", width: "100%", height:"100%", borderRadius:"10px"}} ></img>
    </div>
</Col> */}

<LightGallery>

{gallery.map((item, index) => (

 <img src={item} alt="texr" style={{objectFit:"contain", width: "250px", height:"250px", margin:"2px", borderRadius:"10px"}} ></img>

))}

</LightGallery>




</Row>

    </div>
  )
}

export default ProjectGallery