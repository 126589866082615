import React from 'react';
import { Container,Row,Col } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import { Link } from 'react-router-dom';
import {FaYoutube , FaFacebook, FaInstagram, FaTwitter} from 'react-icons/fa';


import './Footer.css';

function Footer() {
  return (
<Container fluid className='footer'>
    <Container className='spacefooter'>
    <Row>
        <Col sm>
            <h4 className='footerTitle'>About Us</h4>
            <p>Providing Services Web Designing and Development, App Development, Bulk SMS Services, Graphic Designing and Digital Marketing.Spiderhit Company Develop Customer Requirement based Websites and Applications.</p>
           <br/>
            <FaFacebook className='iconsfooter'/> 
        <FaInstagram className='iconsfooter'/>
        <FaTwitter className='iconsfooter'/>

       <FaYoutube className='iconsfooter'/> 
        </Col>
        <Col sm><h4 className='footerTitle'>Main Links</h4>
       
      <ListGroup className='menu'><Link to="/" style={{textDecoration: "none",color:"aliceblue"}}>Home</Link></ListGroup>
      <ListGroup className='menu'><Link to="/about" style={{textDecoration: "none",color:"aliceblue"}}>About Us</Link></ListGroup>
      <ListGroup className='menu'><Link to="/about" style={{textDecoration: "none",color:"aliceblue"}}>Services</Link></ListGroup>
      <ListGroup className='menu'><Link to="/ecommerce" style={{textDecoration: "none",color:"aliceblue"}}>E-commerce</Link></ListGroup>
      <ListGroup className='menu'><Link to="/bulksms" style={{textDecoration: "none",color:"aliceblue"}}>Bulk SMS</Link></ListGroup>
      <ListGroup className='menu'><Link to="/contactus" style={{textDecoration: "none",color:"aliceblue"}}>Contact Us</Link></ListGroup>

    
        </Col>
        <Col sm>
          <h4 className='footerTitle'>Terms</h4>
          <ListGroup className='menu'><Link to="/terms" style={{textDecoration: "none",color:"aliceblue"}}>Terms and Conditions</Link></ListGroup>
      <ListGroup className='menu'><Link to="/PrivacyPolicy" style={{textDecoration: "none",color:"aliceblue"}}>Privacy Policy</Link></ListGroup>
      <ListGroup className='menu'><Link to="/Cancellation" style={{textDecoration: "none",color:"aliceblue"}}>Cancellation Policy</Link></ListGroup>
      <ListGroup className='menu'><Link to="/Delivery" style={{textDecoration: "none",color:"aliceblue"}}>Delivery Policy</Link></ListGroup>

          
          
          </Col>
        <Col sm>
          <h4 className='footerTitle' >Account</h4>
          <ListGroup className='menu'><Link to="/terms" style={{textDecoration: "none",color:"aliceblue"}}>Login</Link></ListGroup>
      <ListGroup className='menu'><Link to="/about" style={{textDecoration: "none",color:"aliceblue"}}>Register</Link></ListGroup>
      <ListGroup className='menu'><Link to="/about" style={{textDecoration: "none",color:"aliceblue"}}>My Account</Link></ListGroup>
      <ListGroup className='menu'><Link to="/about" style={{textDecoration: "none",color:"aliceblue"}}>My Projects</Link></ListGroup>

          </Col>

      </Row>
    </Container>
</Container>

    )
}

export default Footer