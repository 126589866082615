import React from 'react';

function ChildComponent(props) {
    const { id, name } = props;



  return (
    <div>
    <p>ID: {id}</p>
    <p>Name: {name}</p>
  </div>
    )
}

export default ChildComponent