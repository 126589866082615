import React from "react";
import HeaderContainerFluid from "../Components/Header";
import NavHeader from "../Components/Nav";
import Footer from "../Components/Footer";
import FooterBotttom from "../Components/FooterBotttom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import './AboutPage.css';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";
import AboutImag from '../images/about.png';



function AboutPage() {


    const navigate = useNavigate();


    function handleSubmit(){

        navigate('/contactUs');
      
      
      
      }
return(
    <div>
        <HeaderContainerFluid></HeaderContainerFluid>
<NavHeader></NavHeader>
<div className='title'>About Us</div>
<Container>
    <Row className="aboutspace align-items-center">
    <Col sm={6}>
        <div><img style={{objectFit:"contain", width: "100%", height:"100%", borderRadius:"10px"}} src={AboutImag} alt="images" thumbnail></img>
</div>
    </Col>

        <Col sm={6}>
            <div style={{marginTop:"20px",}}>
                <p style={{marginTop:"20px",}}>WHO WE ARE</p>
                <h2 style={{marginTop:"20px", fontWeight:"700"}}>
                We Are All In One IT Solution & Technology Company
                </h2>
                <p className='paraspace'>
                We are leading Software solutions providing company all over the world doing over 8 years. Providing Services Web Designing and Development, App Development, Bulk SMS Services, Graphic Designing and Digital Marketing.Spiderhit Company Develop Customer Requirement based Websites and Applications.


                </p>
                <br></br>
                <Button variant="primary" size="lg" onClick = {handleSubmit}>
          Contact Us
        </Button>{' '}
            </div>
        </Col>

    </Row>
</Container>
<Footer></Footer>
<FooterBotttom></FooterBotttom>
    </div>
)


}

export default AboutPage;