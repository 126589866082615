import React from 'react';
import Form from 'react-bootstrap/Form';
import { useState } from "react";
import './ContactForm.css';
import Button from 'react-bootstrap/Button';




function ContactForm() {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [service, setService] = useState("");

    const [message, setMessage] = useState("");




  
let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let res = await fetch("https://httpbin.org/post", {
        method: "POST",
        body: JSON.stringify({
          name: name,
          email: email,
          mobileNumber: mobileNumber,
          service:service,
        }),
      });
      let resJson = await res.json();
      if (res.status === 200) {
        setName("");
        setEmail("");
        setMessage("Details Submitted successfully");
      } else {
        setMessage("Some error occured");
      }
    } 
    
    catch (err) {
      console.log(err);
    }
  };

  return (
<div >
      <form onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="formGroupName">
        <Form.Label>Your Name address</Form.Label>
        <Form.Control type="text"  value={name} placeholder="Spiderhit" 
        onChange={(e) => setName(e.target.value)}
        />
      </Form.Group>
        

      <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Label className='label'>Your Email address</Form.Label>
        <Form.Control type="email"  value={email} placeholder="Sales@spiderhit.com" 
        onChange={(e) => setEmail(e.target.value)}
        />
      </Form.Group>
       
       
      <Form.Group className="mb-3" controlId="formGroupName">
        <Form.Label>Your Mobile Number</Form.Label>
        <Form.Control type="Number"  value={mobileNumber} placeholder="7411122189" 
        onChange={(e) => setMobileNumber(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formGroupName">
        <Form.Label>Your Looking for?</Form.Label>
        <Form.Control type="text"  value={service} placeholder="Website,SMS ...." 
        onChange={(e) => setService(e.target.value)}
        />
      </Form.Group>

       

        <Button variant="primary" type="submit">Submit</Button>
        <div className="message">{message ? <p>{message}</p> : null}</div>

      </form>

    </div>
  );
}

export default ContactForm;